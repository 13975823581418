// SORTED WEBSITES FROM CMS
//@ts-nocheck
export const websites = [
  'global',
  'giorgio-armani',
  'ga-poldo',
  'ga-neve',
  'thats-so-armani',
  'ginza-tower',
  'emporio-armani',
  'ea7',
  'armani-exchange',
  'armani-casa',
  'armani-fiori',
  'armani-dolci',
  'armani-beauty',
  'armani-restaurant',
  'armani-hotels-resorts',
  'armani-silos',
  'armani-clubs',
  'echi-dal-mondo',
] as const

export const externalBrands = [
  'ga-poldo',
  'ga-neve',
  'thats-so-armani',
  'ginza-tower',
  'armani-dolci',
  'armani-beauty',
  'armani-hotels-resorts',
  'armani-silos',
  'echi-dal-mondo',
]

export const brands = websites.filter(
  website => website !== 'global' && !externalBrands.includes(website)
)
