import { SEARCH_DEPLOYMENT_ID } from '@integration-layer/data/xGenIds'
import type { AlgoliaProductType } from '@integration-layer/modules/05.productServices/runtime/components/WrapperProductTile.props'

type SearchParams = {
  q: string
  brand?: string
  color?: string | string[]
  microCat?: string | string[]
  macroCat?: string | string[]
  gender?: string | string[]
  size?: string | string[]
  age?: string | string[]
  page?: number
}

type RecommendationParams = {
  predictionID: string
}

interface XgenIntegration {
  search: (query: string, options: SearchOptions) => Promise<any>
  getXgenPrediction: (predictionID: string) => Promise<any>
  setContext: (attribute: string, value: string) => void
  clearContext: () => void
}

interface SearchOptions {
  deploymentId: string
  page: number
  context: { [key: string]: string | string[] }
  facets: boolean
}

export const useXgen = () => {
  const API_KEY = useRuntimeConfig().public.xgen

  const xgen: Ref<XgenIntegration | undefined> = ref(undefined)

  const checkScript = async () => {
    if (!xgen.value) {
      const url = `https://assets.xgen.dev/js/${API_KEY}/sdkIntegration.js`
      const { integration } = await import(/* @vite-ignore */ url)
      xgen.value = integration as XgenIntegration
    }
  }

  const search = async ({
    q,
    brand,
    color,
    microCat,
    macroCat,
    gender,
    size,
    age,
    page,
  }: SearchParams) => {
    await checkScript()
    if (!xgen.value) {
      throw new Error('Xgen integration is not loaded.')
    }

    const { search } = xgen.value

    const context = {
      Brand: brand ?? '*',
      ColorDesc: color?.length ? color : '*', // ['Blue', 'Black']
      macroCat: macroCat?.length ? macroCat : '*', // ['tshirt']
      microCat: microCat?.length ? microCat : '*',
      Gender: gender?.length ? gender : '*',
      size: size?.length ? size : '*', // ['XL']
      Age: age?.length ? age : '*',
    }

    return search(q, {
      deploymentId: SEARCH_DEPLOYMENT_ID,
      page: page ?? 0,
      context,
      facets: true,
    })
  }

  const recommendations = async ({
    predictionID,
  }: RecommendationParams): Promise<AlgoliaProductType[]> => {
    await checkScript()
    if (!xgen.value) {
      throw new Error('Xgen integration is not loaded.')
    }

    const { getXgenPrediction } = xgen.value

    return getXgenPrediction(predictionID)
  }

  return {
    checkScript,
    search,
    recommendations,
  }
}
