export function useInsideChat() {
  const insideChat = useState<{
    isAvailable: boolean
    isLoaded: boolean
    hasBeenRequestedBeforeInit: boolean
  }>(() => ({
    isAvailable: false,
    isLoaded: false,
    hasBeenRequestedBeforeInit: false,
  }))

  const openInsideChat = () => {
    try {
      if (insideChat.value.isLoaded) {
        // @ts-ignore
        window?.insideFrontInterface?.openChatPane()
      } else insideChat.value.hasBeenRequestedBeforeInit = true
    } catch (error) {
      console.error('Error in openInsideChat() : ', error)
    }
  }

  return {
    insideChat,
    openInsideChat,
  }
}
