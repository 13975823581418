export const convertStringToSlug = (text: string) => {
  return (
    text
      .trimEnd()
      .toLowerCase()
      .replace(/\s+/g, '-')
      // the following `replace` makes it so that he slug will only contain alphabet characters (\w),
      // Chinese and Japanese characters (㐀-䶵一-鿋豈-頻ぁ-んァ-ン),
      // Korean Hangul characters (\u3131-\uD79D), and dashes (-)
      .replace(/[^\w㐀-䶵一-鿋豈-頻ぁ-んァ-ン\u3131-\uD79D-]+/gu, '')
      .replace(/--+/g, '-')
  )
}

export const convertStringToPascalCase = (text: string) => {
  return text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase())
    .replace(/^./, match => match.toUpperCase())
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const createSlug = (...strings: string[]): string => {
  return strings.map(str => convertStringToSlug(str)).join('-')
}

export function parseNullableStringToNumber(string: string | null | undefined) {
  return !!string && !isNaN(Number.parseInt(string))
    ? Number.parseInt(string)
    : undefined
}

/**
 * Validates whether the provided FormKit node value is a valid order or return number.
 * The value must contain only alphanumeric characters and hyphens.
 *
 * @param {FormKitNode} node - The FormKit node containing the value to validate.
 * @returns {boolean} - True if the value is valid, otherwise false.
 */
export const validateOrderOrReturnNumber = (node: FormKitNode): boolean => {
  // Regex pattern allowing only letters, numbers, and hyphens
  // Regex example https://regex101.com/r/OQ4FnJ/1
  const pattern = /^[a-zA-Z0-9-]+$/
  return pattern.test(node.value as string)
}
