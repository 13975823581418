import type { PlpGridType } from '@design-system/components/Plp/FunctionalBar/PlpFunctionalBar.props'

export default defineNuxtRouteMiddleware(to => {
  const app = useNuxtApp()
  const hasEditorialContent = app.payload['has-plp-moodboard']

  if (!hasEditorialContent) return

  const { currentAlgoliaIndex } = useAppConfig()
  const { currentWebsite } = useWebsite()

  const selectedGridValue = useCookie<{ name: PlpGridType | undefined }>(
    `${currentWebsite.value}-grid-layout-cookies`
  )

  const hasDynamicListingQuery = to.query.order

  if (
    !hasDynamicListingQuery &&
    (!selectedGridValue.value ||
      selectedGridValue.value?.name === 'gridMoodboard')
  ) {
    return navigateTo({
      path: to.path,
      query: {
        order: `dynamic_listing-${currentAlgoliaIndex}`,
        filter: to.query.filter ? to.query.filter : undefined,
        page: to.query.page ? to.query.page : undefined,
      },
    })
  }
})
