import type { GASetupDatalayerParamWishlist } from '@integration-layer/schemas/GAEvents/dataLayerSetup'
import type { WishlistView } from '@integration-layer/composables/useGAWishlistEvents'
import type {
  WishlistItem,
  WishlistStorageItem,
} from '@integration-layer/utils/wishlist'

type AddOrRemoveItemParams = {
  item: Omit<WishlistItem, 'notifyMe'>
  view?: WishlistView
  forceRefresh?: boolean
}

export const useWishlist = () => {
  const configs = useConfigs()
  const appConfig = useAppConfig()
  const language = appConfig.currentLanguage
  const algoliaIndex = appConfig.currentAlgoliaIndex
  const { $cl } = useNuxtApp()
  const { isLogged } = useLogin()
  const { addNotification, deleteNotification } = useWishlistFeedback()
  const { dispatchAddToWishlistEvent, dispatchRemoveFromWishlistEvent } =
    useGAWishlistEvents()

  const wishlistItemsState = useState<WishlistItem[]>(
    'WISHLIST_STATE',
    () => []
  )

  const deletedWishlistItems = useState<WishlistItem[]>(
    'DELETED_WISHLIST_ITEMS',
    () => []
  )

  const isWishlistLoading = useState('IS_WISHLIST_LOADING', () => true)
  const isDeletingAll = useState('IS_DELETING_ALL', () => false)

  const wishlistStorage = isLogged.value
    ? authWishlistStorage
    : guestWishlistStorage

  const fetchProductsData = async (storedItems: WishlistStorageItem[]) => {
    const productCodes = storedItems.map(({ productCode }) => productCode)
    const algoliaProducts = productCodes.length
      ? await $fetch(`/api/getProducts/${algoliaIndex}`, {
          query: {
            mfcList: productCodes.join(','),
          },
        })
      : []

    const filteredAlgoliaProducts = algoliaProducts.filter(isNonNullable)
    const skuCodes = filteredAlgoliaProducts.reduce<string[]>(
      (acc, { size }) => {
        const skus = size.flatMap(({ SKU }) => SKU ?? [])
        acc.push(...skus)
        return acc
      },
      []
    )

    //Api call inside the ternary to not trigger it beforehand if skuCodes.length is 0
    const skus = skuCodes.length
      ? (
          await Promise.all(
            chunk(skuCodes, 24).map(skuCodesChunk =>
              $cl.skus.list({
                include: [
                  'prices',
                  'stock_items',
                  'stock_items.reserved_stock',
                ],
                filters: {
                  code_in: skuCodesChunk?.join() ?? '',
                },
                pageSize: 24,
              })
            )
          )
        ).flat()
      : []

    return mapWishlistItems(
      storedItems,
      filteredAlgoliaProducts,
      skus,
      configs.value.country,
      language
    )
  }

  const initWishlist = async () => {
    try {
      isWishlistLoading.value = true
      const wishlist = await wishlistStorage.get()
      wishlistItemsState.value = await fetchProductsData(wishlist)
    } catch (e) {
      console.error('init wishlist error:', e)
    } finally {
      isWishlistLoading.value = false
    }
  }

  // GA Datalayer event
  const wishlistEntity = useGAEntity<GASetupDatalayerParamWishlist>(
    'GA_DL_SETUP_WISHLIST'
  )
  // all item data
  const wishlistItems = computed(() => wishlistItemsState.value ?? [])
  // country, code, website
  const rawWishlist = computed(() =>
    mapStorageItems(wishlistItems.value, configs.value.country)
  )

  const wishlistItemsByBrand = computed(() =>
    sortItemsByBrand(wishlistItems.value, item => item.productBrand)
  )

  const nProducts = computed(() => wishlistItems.value.length)
  const isWishlistEmpty = computed(() => !nProducts.value)
  const wishlistProductCodes = computed(() =>
    rawWishlist.value.reduce<{ [key: string]: boolean }>(
      (acc, { productCode }) => {
        if (!acc[productCode]) acc[productCode] = true
        return acc
      },
      {}
    )
  )

  const mergeWishlists = async () => {
    if (!isLogged.value) return
    const guestWishlist = await guestWishlistStorage.get()
    const itemsToAdd = diffWishlists(guestWishlist, rawWishlist.value)
    if (!itemsToAdd.length) return
    const wishlistItems = await fetchProductsData(itemsToAdd)
    await addItems(wishlistItems)
    await guestWishlistStorage.delete()
  }

  const wishlistOptimisticUpdateHandle = async (
    wishlistItems: WishlistItem[]
  ) => {
    const wishlistBeforeUpdate = [...wishlistItemsState.value]
    try {
      // optimistic update
      wishlistItemsState.value = wishlistItems
      await wishlistStorage.createOrUpdate([...rawWishlist.value])
    } catch (e) {
      console.error(e)
      wishlistItemsState.value = [...wishlistBeforeUpdate]
    }
  }

  const updateNotifyMe = useDebounceFn(
    async (productCode: string, notifyMe: boolean) => {
      const updatedWishlistItems = wishlistItemsState.value.map(item =>
        item.productSku === productCode
          ? {
              ...item,
              notifyMe,
            }
          : item
      )
      await wishlistOptimisticUpdateHandle(updatedWishlistItems)
    },
    250
  )

  const addItems = async (items: WishlistItem[]) => {
    if (!items.length) return
    const itemsWithNotifyMe = items.map(item => ({
      ...item,
      ...(item.productSku ? { notifyMe: true } : {}),
      productBrand: formatBrand(item.productBrand),
    }))
    const updateItems = [...wishlistItemsState.value, ...itemsWithNotifyMe]
    await wishlistOptimisticUpdateHandle(updateItems)
  }

  const addItem = async (item: WishlistItem) => {
    deleteNotification(item.productCode)
    await addItems([item])
    addNotification(item, WishlistSnackbarAction.addToWishlist)
  }

  const restoreItem = async (item: WishlistItem | null) => {
    if (!item) return
    deleteNotification(item.productCode)
    await addItems([item])
  }

  const removeItem = async (
    productCode: string,
    disableNotification = false
  ) => {
    deleteNotification(productCode)
    const { removedItem, updatedItems } = diffItems(
      wishlistItemsState.value,
      productCode
    )
    await wishlistOptimisticUpdateHandle(updatedItems)

    if (disableNotification) return removedItem
    addNotification(removedItem, WishlistSnackbarAction.removeFromWishlist)
    return removedItem
  }

  const deferredRemoveItem = () => {
    let updatedWishlist: WishlistStorageItem[] = [...rawWishlist.value]
    const removedItemsFromStorage: {
      pos: number
      value: WishlistStorageItem
    }[] = []
    return {
      removeItemFromStorage: async (item: WishlistItem) => {
        const storageItemIndex = rawWishlist.value.findIndex(
          ({ productCode }) => item.productCode === productCode
        )
        removedItemsFromStorage.push({
          pos: storageItemIndex,
          value: rawWishlist.value[storageItemIndex],
        })
        updatedWishlist = updatedWishlist.filter(
          ({ productCode }) => item.productCode !== productCode
        )
        await wishlistStorage.createOrUpdate(updatedWishlist).catch(e => {
          console.error(e)
        })
      },
      completeRemoveItem: async (item: WishlistItem) => {
        wishlistItemsState.value = wishlistItemsState.value.filter(
          ({ productCode }) => item.productCode !== productCode
        )
      },
      undoRemoveItem: async (item: WishlistItem) => {
        const itemToRestore = removedItemsFromStorage.find(
          ({ value }) => value.productCode === item.productCode
        )
        if (!itemToRestore) return
        const { pos, value } = itemToRestore
        updatedWishlist.splice(pos, 0, value)
        await wishlistStorage.createOrUpdate(updatedWishlist)
      },
    }
  }

  const addOrRemoveItem = useDebounceFn(
    async ({ item, view, forceRefresh = true }: AddOrRemoveItemParams) => {
      const alreadyExists = rawWishlist.value.find(
        ({ productCode }) => item.productCode === productCode
      )
      if (alreadyExists) {
        const removedItem = await removeItem(item.productCode)
        if (removedItem) dispatchRemoveFromWishlistEvent([removedItem])
        return
      }
      const wishlistItems = forceRefresh
        ? await fetchProductsData(
            mapStorageItems([item], configs.value.country)
          )
        : [item]
      dispatchAddToWishlistEvent(wishlistItems, view)
      await addItem(wishlistItems[0])
    },
    250
  )

  const restoreWishlist = async () => {
    try {
      deleteNotification('all')
      await wishlistOptimisticUpdateHandle([...deletedWishlistItems.value])
    } catch (e) {
      console.error(e)
    }
    deletedWishlistItems.value = []
  }

  const deleteWishlist = async () => {
    if (!rawWishlist.value.length) return
    try {
      isDeletingAll.value = true
      deletedWishlistItems.value = [...wishlistItemsState.value]

      // optimistic update
      wishlistItemsState.value = []
      dispatchRemoveFromWishlistEvent(deletedWishlistItems.value)
      await wishlistStorage.delete()
      addNotification(null, WishlistSnackbarAction.removeAll)
    } catch (e) {
      console.error(e)
      restoreWishlist()
    } finally {
      isDeletingAll.value = false
    }
  }

  const refreshWishlistOnTabChange = () => {
    const _refresh = async () => {
      if (document.hidden) return
      await initWishlist()
    }

    return {
      addWishlistTabChangeListener: () => {
        document.addEventListener('visibilitychange', _refresh)
      },
      removeWishlistTabChangeListener: () => {
        document.removeEventListener('visibilitychange', _refresh)
      },
    }
  }

  watch(wishlistItemsByBrand, () => {
    wishlistEntity.value = {
      armani_exchange_wishlist:
        wishlistItemsByBrand.value.get('armani-exchange')?.length ?? 0,
      ea7_wishlist: wishlistItemsByBrand.value.get('ea7')?.length ?? 0,
      emporio_armani_wishlist:
        wishlistItemsByBrand.value.get('emporio-armani')?.length ?? 0,
      giorgio_armani_wishlist:
        wishlistItemsByBrand.value.get('giorgio-armani')?.length ?? 0,
      wishlist_products: wishlistItems.value.length,
      wishlistTotalValue: wishlistItems.value.reduce(
        (acc, { price }) => (acc += price),
        0
      ),
    }
  })

  return {
    initWishlist,
    wishlist: rawWishlist,
    wishlistItems,
    wishlistItemsByBrand,
    wishlistProductCodes,
    nProducts,
    isWishlistEmpty,
    isWishlistLoading,
    isDeletingAll,
    mergeWishlists,
    updateNotifyMe,
    addItem,
    restoreItem,
    removeItem,
    deferredRemoveItem,
    addOrRemoveItem,
    restoreWishlist,
    deleteWishlist,
    refreshWishlistOnTabChange,
  }
}
