import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _000_45set_45locale_45cookie_45global from "/opt/build/repo/packages/global/middleware/000.setLocaleCookie.global.ts";
import _02_45redirect_45to_45canonical_45global from "/opt/build/repo/packages/global/middleware/02.redirectToCanonical.global.ts";
import _03_45check_45sold_45out_45global from "/opt/build/repo/packages/global/middleware/03.checkSoldOut.global.ts";
import _03_45website_45global from "/opt/build/repo/packages/global/middleware/03.website.global.ts";
import ammend_45country_45locale_45global from "/opt/build/repo/packages/global/middleware/ammendCountryLocale.global.ts";
import close_45all_45dialogs_45global from "/opt/build/repo/packages/global/middleware/closeAllDialogs.global.ts";
import close_45all_45snackbars_45global from "/opt/build/repo/packages/global/middleware/closeAllSnackbars.global.ts";
import configs_45global from "/opt/build/repo/packages/global/middleware/configs.global.ts";
import fetch_45page_45global from "/opt/build/repo/packages/global/middleware/fetchPage.global.ts";
import fetch_45page_45init_45states_45global from "/opt/build/repo/packages/global/middleware/fetchPageInitStates.global.ts";
import handle_45dynamic_45listing_45global from "/opt/build/repo/packages/global/middleware/handleDynamicListing.global.ts";
import navigate_45from_45checkout_45global from "/opt/build/repo/packages/global/middleware/navigateFromCheckout.global.ts";
import navigate_45to_45home_45from_45successful_45registered_45global from "/opt/build/repo/packages/global/middleware/navigateToHomeFromSuccessfulRegistered.global.ts";
import navigate_45to_45my_45account_45global from "/opt/build/repo/packages/global/middleware/navigateToMyAccount.global.ts";
import navigate_45to_45wishlist_45global from "/opt/build/repo/packages/global/middleware/navigateToWishlist.global.ts";
import redirect_45short_45pdp_45url_45global from "/opt/build/repo/packages/global/middleware/redirectShortPdpUrl.global.ts";
import riskified_45script_45handler_45global from "/opt/build/repo/packages/global/middleware/riskifiedScriptHandler.global.ts";
import sidebar_45newsletter_45handler_45global from "/opt/build/repo/packages/global/middleware/sidebarNewsletterHandler.global.ts";
import site_45not_45shoppable_45global from "/opt/build/repo/packages/global/middleware/siteNotShoppable.global.ts";
export const globalMiddleware = [
  validate,
  _000_45set_45locale_45cookie_45global,
  _02_45redirect_45to_45canonical_45global,
  _03_45check_45sold_45out_45global,
  _03_45website_45global,
  ammend_45country_45locale_45global,
  close_45all_45dialogs_45global,
  close_45all_45snackbars_45global,
  configs_45global,
  fetch_45page_45global,
  fetch_45page_45init_45states_45global,
  handle_45dynamic_45listing_45global,
  navigate_45from_45checkout_45global,
  navigate_45to_45home_45from_45successful_45registered_45global,
  navigate_45to_45my_45account_45global,
  navigate_45to_45wishlist_45global,
  redirect_45short_45pdp_45url_45global,
  riskified_45script_45handler_45global,
  sidebar_45newsletter_45handler_45global,
  site_45not_45shoppable_45global
]
export const namedMiddleware = {}