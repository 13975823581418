import type { GASetupDatalayerParamGlobal } from '@integration-layer/schemas/GAEvents/dataLayerSetup'

export const useParamGlobal = (): GASetupDatalayerParamGlobal => {
  const environment = useRuntimeConfig().public.algoliaEnv
  const formattedEnvironment =
    environment === 'dev_' ? 'development' : 'production'
  const device = userDevice

  const { language, country } = useRouteHelper()
  const currency = useCurrency().getCurrencyCodeFromCountryCode(country) ?? ''

  const date = new Date()
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  const hour = date.getHours()
  const hourString = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  const partOfDay = () => {
    if (hour >= 0 && hour < 6) {
      return 'night'
    } else if (hour >= 6 && hour < 12) {
      return 'morning'
    } else if (hour >= 12 && hour < 18) {
      return 'afternoon'
    } else {
      return 'evening'
    }
  }

  const officeHours = () => {
    if ((hour >= 8 && hour <= 11) || (hour >= 14 && hour <= 18)) {
      return 'office'
    } else if (hour >= 12 && hour <= 13) {
      return 'lunch'
    } else {
      return 'out_of_office'
    }
  }

  const formattedHour = `${hourString}/${minutes}`

  const formattedDate = `${day}/${month}/${year}`

  const timestamp = date.toLocaleString()

  const countryFiltered = country.includes('?')
    ? country.split('?')[0]
    : country

  return {
    environment: formattedEnvironment,
    device,
    current_shipping: countryFiltered,
    website_language: language,
    selected_currency: currency,
    localDate: formattedDate,
    local_hour_minute: formattedHour,
    local_parts_of_day: partOfDay(),
    local_office_hours: officeHours(),
    timestamp,
  }
}
