declare global {
  interface Window {
    _inside: Array<any>
    insideFrontInterface: any
  }
}

export default defineNuxtPlugin({
  name: 'powerfront',
  async setup() {
    const { insideChat } = useInsideChat()

    const setupChat = () => {
      window._inside ||= []

      if (window._inside && Array.isArray(window._inside))
        window._inside.push({
          action: 'bind',
          name: 'chatavailable',
          callback: (available: boolean) => {
            try {
              insideChat.value.isLoaded = true
              insideChat.value.isAvailable = available

              if (available && insideChat.value.hasBeenRequestedBeforeInit) {
                // @ts-ignore
                window.insideFrontInterface.openChatPane()
              }
            } catch (error) {
              console.error('Error in livechat callback: ', error)
            }
          },
        })
    }

    setupChat()

    useScript({
      async: true,
      src: 'https://cdn8.eu.inside.chat/gtm/IN-1001177-NEW/include.js',
    })
  },
})
