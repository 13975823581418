<script setup lang="ts">
import type { CookieBannerCenterProps } from '@design-system/components/Cookie/CookieBannerCenter.props'

const props = defineProps<CookieBannerCenterProps>()

const emit = defineEmits<{
  onAcceptAll: []
  onSave: []
  onToggleCookie: [string, boolean]
}>()

const modalBannerCenterId = 'modal-cookie-banner-center'

const { closeDialog } = useDialog()
const localePath = useLocalePath()

const preferencesError = ref(false)

const acceptAllCookies = () => {
  emit('onAcceptAll')
  closeDialog(modalBannerCenterId)
}

const saveCookies = () => {
  if (props.cookies.every(cookie => cookie.selected !== undefined)) {
    emit('onSave')
    closeDialog(modalBannerCenterId)
  } else {
    preferencesError.value = true
  }
}
</script>

<template>
  <OrganismsModal
    :id="modalBannerCenterId"
    hide-close-button
    auto-height
    position="center"
    with-overlay
    close-on-touch-outside
    class="z-[100]"
    @wheel.stop="''"
  >
    <template #body>
      <div class="flex flex-col gap-6">
        <div class="flex justify-between">
          <h1 class="text-medium-5 text-text-primary mt-1 uppercase">
            {{ $ts('cookieBanner.centerTitle') }}
          </h1>
          <button
            :aria-label="$ts('close')"
            @click="closeDialog(modalBannerCenterId)"
          >
            <DefaultIconsClose
              aria-hidden="true"
              class="text-text-primary h-8 w-8"
            />
          </button>
        </div>
        <div class="flex flex-col gap-6">
          <div
            class="border-primitives-grey-100 flex flex-col gap-6 border-b pb-6"
          >
            <UtilsMarkdown
              class="text-light-6 text-text-primary"
              :content="
                $ts('cookieBanner.privacyCenter', {
                  url: localePath('/legal/cookie-policy'),
                })
              "
              container="p"
            />
          </div>
          <div
            v-for="cookie in cookies"
            :key="cookie.title"
            class="border-primitives-grey-100 flex flex-col gap-6 border-b pb-6"
          >
            <h2 v-if="cookie.title" class="text-book-5 text-text-primary">
              {{ cookie.title }}
            </h2>
            <UtilsMarkdown
              v-if="cookie.text"
              class="text-light-6 text-text-primary"
              :content="cookie.text"
              container="p"
            />
            <div class="flex justify-end [&>div>*]:uppercase">
              <AtomsToggle
                :show-off-button="cookie.offButton"
                :selected="cookie.selected ?? cookie.defaultSelected"
                :text-on-button="$ts('cookieBanner.onButton')"
                :text-off-button="$ts('cookieBanner.offButton')"
                @on-selected="emit('onToggleCookie', cookie.id, $event)"
              />
            </div>
          </div>
          <div
            class="border-primitives-grey-100 flex flex-col gap-6 border-b pb-6"
          >
            <UtilsMarkdown
              class="text-light-6 text-text-primary"
              :content="
                $ts('cookieBanner.thirdPartyCookies', {
                  url: localePath('/legal/cookie-policy'),
                })
              "
              container="p"
            />
          </div>
        </div>
        <div
          v-if="preferencesError"
          class="text-primitives-red bg-primitives-grey-50 flex w-full items-center gap-1 px-4 py-2"
        >
          <DefaultIconsInfo aria-hidden="true" class="'h-4 w-4" />
          <p class="text-book-7">
            {{ $ts('cookieBanner.preferencesError') }}
          </p>
        </div>
        <div class="cookie-center-container-cta flex justify-center gap-6">
          <AtomsCta
            anatomy="primary"
            size="l"
            type="button"
            :aria-label="$ts('cookieBanner.acceptAll')"
            @click-handler="acceptAllCookies"
            ><template #label>
              {{ $ts('cookieBanner.acceptAll') }}
            </template></AtomsCta
          >
          <AtomsCta
            anatomy="secondary"
            size="l"
            type="button"
            :aria-label="$ts('cookieBanner.save')"
            @click-handler="saveCookies"
          >
            <template #label>
              {{ $ts('cookieBanner.save') }}
            </template>
          </AtomsCta>
        </div>
      </div>
    </template>
  </OrganismsModal>
</template>

<style lang="scss" scoped>
.cookie-center-container-cta {
  flex-wrap: wrap;
  @media screen and (min-width: 410px) {
    @apply flex-nowrap;
  }

  & button {
    flex: 1 1 auto;
    @media screen and (min-width: 410px) {
      @apply flex-none;
    }
  }
}
</style>
