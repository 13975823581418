import type { Views } from '@integration-layer/schemas/GAEvents'
import type { WishlistItem } from '@integration-layer/utils/wishlist'
import {
  addToWishlistSchema,
  removeFromWishlistSchema,
} from '@integration-layer/schemas/GAEvents/wishlist'
import { countries } from '@design-system/configs/countries'
import snakeCase from 'lodash/snakeCase'

export type WishlistView = Extract<
  Views,
  'product_listing_page' | 'product_page' | 'cart_page' | 'wishlist_page'
>

export const useGAWishlistEvents = () => {
  const configs = useConfigs()
  const debug = !!useRuntimeConfig().public.ga4.debug

  const mapData = (items: WishlistItem[]) => {
    const _country = countries.find(
      ({ code }) => code === configs.value.country
    )
    const currency = items[0]?.currency ?? _country?.cl.currency ?? 'EUR'

    const _items = items?.map((item, i) => {
      const categories = item?.categories

      return {
        item_id: item.productCode,
        item_name: snakeCase(item.title ?? ''),
        affiliation: 'armani.com',
        coupon: '',
        discount: 0,
        index: i + 1,
        item_brand:
          item.productBrand.toLowerCase() === 'ea7'
            ? 'EA7'
            : snakeCase(item.productBrand ?? 'armani group'),
        item_category: snakeCase(categories?.category1 ?? ''),
        item_category2: snakeCase(categories?.category2 ?? ''),
        item_category4: snakeCase(categories?.category4 ?? ''),
        item_list_id: '',
        item_list_name:
          snakeCase(`${item.productBrand}-${categories?.category2}`) ?? '',
        item_variant: item.selectedColor,
        price: item.price,
        quantity: 1,
        item_availability: item.isAvailable ? 1 : 0,
        item_size: item.selectedSize ?? '',
        item_reservable: 0,
        item_discount: item.isDiscounted ? 1 : 0,
        item_mfc_id: item.MFC ?? item.productCode.replace(/-/g, ''),
        item_img: item.selectedImage,
      } as const
    })

    return {
      _items,
      currency,
      totalPrice: _items?.reduce((acc, item) => acc + item.price, 0),
    }
  }

  const dispatchAddToWishlistEvent = (
    items: WishlistItem[],
    view?: WishlistView
  ) => {
    const { _items, currency, totalPrice } = mapData(items)
    if (!_items?.length) return
    useGASendEvent(
      addToWishlistSchema,
      {
        event: 'add_to_wishlist',
        eventID: '002',
        ecommerce: {
          view_type: view ?? 'product_listing_page',
          currency,
          value: totalPrice,
          items: _items,
        },
      },
      {
        clearEcommerce: true,
        debug,
      }
    )
  }

  const dispatchRemoveFromWishlistEvent = (items: WishlistItem[]) => {
    const { _items, currency, totalPrice } = mapData(items)
    if (!_items?.length) return
    useGASendEvent(
      removeFromWishlistSchema,
      {
        event: 'remove_from_wishlist',
        eventID: '003',
        ecommerce: {
          item_list_id: '',
          item_list_name: _items.length === 1 ? _items[0].item_list_name : '',
          currency,
          value: totalPrice,
          items: _items,
        },
      },
      { clearEcommerce: true, debug }
    )
  }

  return {
    dispatchAddToWishlistEvent,
    dispatchRemoveFromWishlistEvent,
  }
}
