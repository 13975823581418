export const removeLastEmptyParam = <T extends string | string[]>(
  params: T
): T | string[] =>
  Array.isArray(params) && params?.at(-1) === '' ? params.slice(0, -1) : params

/**
 * Converts an absolute URL to a relative URL
 *
 * @param {string} url - The URL to convert
 *
 * @returns {string} The converted URL to relative URL, removing any commas in the URL
 */
export const toRelativeUrl = (url: string): string => {
  const { pathname, search, hash } = new URL(url)
  return `${pathname}${search}${hash}`.replaceAll(/,/g, '')
}
