type MarketSetting = {
  region: string
  max_MFCS: number
  max_cart: number
  klarnaBNPLMaxPriceCents?: number
  paypalBNPLMaxPriceCents?: number
  klarnaPlacementKey?: string
}
export type MarketSettings = { default: MarketSetting } & Record<
  string,
  MarketSetting
>
export const marketSettings: MarketSettings = {
  default: {
    region: '',
    max_MFCS: 2,
    max_cart: 10,
  },
  it: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 150000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  au: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_RE40S0lZOXFDV3U2TGx0UnAhSjRCZm5DZXM0IWFTenUsOTNlYzg0ODQtMmNkNS00NDY3LTk1ZDItMGEzMGIyOGViNmFhLDEsZzZHKzA2Ui9qd0hreFcyN2ZXYVZxL0YxQ3g5WkRXcHY4R0pCckZvTkUvUT0',
  },
  at: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 100000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  be: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  bg: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  hr: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  cz: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  dk: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 5000000,
    klarnaBNPLMaxPriceCents: 5000000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  us: {
    region: 'US',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 100000,
    klarnaPlacementKey:
      'klarna_live_client_SXNUOFd1NHU0V3loRy1aRWQyUTFLcTlldDMzI3B6RlAsOGVmOTY0ZGMtM2MzNy00MzBhLTk0MGYtNTEzZmY2YmMzZDEwLDEsZ2ptNXFvVjlBWTFCMmxQQ0p2TDlhU05YWU5xaU1sZUQycWZ3L21lS3Zwaz0',
  },
  ee: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  fi: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  fr: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 150000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  de: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 100000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  gr: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 100000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  hu: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  ie: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 150000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  lv: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  lt: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  lu: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  nl: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 400000,
    klarnaBNPLMaxPriceCents: 400000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  pl: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  pt: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 100000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  sk: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  si: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  es: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 150000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  se: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 3600000,
    klarnaBNPLMaxPriceCents: 3600000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  ch: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  gb: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 200000,
    klarnaBNPLMaxPriceCents: 200000,
    klarnaPlacementKey:
      'klarna_live_client_OVhRRXdKL3hiKFN6Zy8pb1hlN2MpMGFGY1owVFQpZUMsZDViZjk2M2YtNjgyMC00ODRiLTkwMTItZGY3NDNiNTYwMzM4LDEsR3dqTHF4eTU4cTZyVkRLbCtKbHZHdVNWUWpoWFFidEludkxnMTFRTWdTQT0',
  },
  ca: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
  },
  cl: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  hk: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
  },
  id: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  il: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  nz: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  ph: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  ro: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  sg: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
  },
  za: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    paypalBNPLMaxPriceCents: 250000,
    klarnaBNPLMaxPriceCents: 200000,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  kr: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  tw: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  th: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  jp: {
    region: 'JP',
    max_MFCS: 2,
    max_cart: 10,
  },
  tr: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  my: {
    region: 'EU',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
  wx: {
    region: '',
    max_MFCS: 2,
    max_cart: 10,
    klarnaPlacementKey:
      'klarna_live_client_Tkw3KHJTNTVpWCk1c3k1QTczRUFFclhqd3o3OVd1TWgsMTRmNWQ3MGQtZjNlYy00YmZhLTg3NmItZWNhZGFmNzRjOTc1LDEsNjdGQ2l5Z3ZyUVgrbllWUlBtRUpIeHVRKzRKeTN5N2VoSkthNEc5dE9Vbz0',
  },
}
