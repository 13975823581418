<script setup lang="ts">
import type { AtomsCtaProps } from './AtomsCta.props.ts'

const emit = defineEmits(['click-handler'])

const props = withDefaults(defineProps<AtomsCtaProps>(), {
  type: 'button',
  size: 'l',
  iconPosition: 'right',
  iconFadeIn: true,
  iconAnimation: true,
  iconShowDefault: true,
  linkUnderline: true,
  underlineEffect: 'default',
})

// disable underline effect when text is wrapped
const textSpan = useTemplateRef<HTMLElement>('textSpan')
const isTextWrapped = ref(false)

if (props.anatomy === 'link' && props.linkUnderline) {
  useResizeObserver(textSpan, async () => {
    if (textSpan.value) {
      await nextTick()

      let textSpanElement = textSpan.value
      //if label is Markdown
      if (
        textSpan.value?.children.length &&
        textSpan.value?.firstElementChild
      ) {
        textSpanElement = textSpan.value?.firstElementChild as HTMLElement
      }
      isTextWrapped.value = isMultilineText(textSpanElement)
    }
  })
}

const nuxtLinkComponent = resolveComponent('NuxtLink')

const isLink = computed(() => props.type === 'a' && props.link?.to)
const isButton = computed(() => props.type === 'button')

const containerClass = computed(() => {
  const classList = []

  /* Anatomy */
  switch (props.anatomy) {
    case 'secondary':
      classList.push('cta cta-secondary')
      break
    case 'ghost':
      classList.push('cta underline border-transparent')
      break
    case 'link':
      const underlineClass = props.linkUnderline
        ? isTextWrapped.value
          ? `${props.underlineEffect == 'appear' ? 'hover:underline' : 'underline'}`
          : `underline-effect-${props.underlineEffect}`
        : ''
      classList.push(`p-0 text-link-6 w-fit h-auto ${underlineClass}`)
      break
    case 'primary':
    default:
      classList.push('cta cta-primary hover:!bg-primitives-grey-400')
  }

  /* Loading */
  if (props.loading) {
    if (props.anatomy === 'primary') {
      classList.push('bg-primitives-grey-400')
    }
  }

  /* Size */
  switch (props.size) {
    case 's': {
      classList.push('cta-s')
      break
    }
    case 'l':
    default:
      classList.push('cta-l')
  }

  /* Square */
  if (props.square) {
    if (props.anatomy !== 'link') classList.push('cta-large-cart py-4 h-full')
  }

  /* Disabled */
  if (props.disabled) {
    classList.push('opacity-20 pointer-events-none')
  }

  /* Icon Position */
  if (!props.square && props.iconPosition === 'right') {
    classList.push('flex-row-reverse')
  }

  /* Full height */
  if (props.fullHeight) {
    classList.push('h-full')
  }

  /* Full Width */
  if (props.fullWidth) {
    classList.push('w-full')
  }
  /* inline-flex */
  if (props.inline || props.anatomy === 'link') {
    classList.push('inline-flex')
  }
  return classList.join(' ')
})

const animatedIcon = computed(() => {
  return props.iconFadeIn && !props.square && !props.loading && !props.success
})

const clickHandler = () => {
  emit('click-handler')
}
</script>

<template>
  <component
    :is="isLink ? nuxtLinkComponent : type"
    :class="['text-button-2 group flex gap-2', containerClass]"
    :disabled="disabled"
    :aria-label="ariaLabel"
    v-bind="{
      ...(isLink && { ...link }),
      ...(isButton && { ...buttonAttrs }),
    }"
    @click="clickHandler"
  >
    <!-- Icon -->
    <template v-if="anatomy !== 'link'">
      <span
        v-if="$slots.icon || iconShowDefault"
        :class="{
          'transition-[margin,opacity] duration-300 ease-out [&:not(:only-child)]:opacity-0 [&:not(:only-child)]:group-hover:opacity-100 [&:not(:only-child)]:group-focus:opacity-100':
            animatedIcon,
          '-mr-3.5 group-hover:mr-0 group-focus:mr-0':
            iconPosition === 'right' && animatedIcon,
          '-ml-3.5 group-hover:ml-0 group-focus:ml-0':
            iconPosition === 'left' && animatedIcon,
          'cta-icon': !linkIconBigger,
        }"
      >
        <span v-if="success">
          <DefaultIconsCheck aria-hidden="true" />
        </span>
        <span v-else-if="loading">
          <DefaultIconsLoader aria-hidden="true" />
        </span>
        <template v-else>
          <!-- hover icon -->
          <span
            v-if="!iconFadeIn && $slots.secondaryIcon"
            class="hidden group-hover:block group-focus:block"
          >
            <slot name="secondaryIcon" />
          </span>
          <!-- main icon -->
          <span
            :class="{
              'group-hover:hidden group-focus:hidden':
                !iconFadeIn && $slots.secondaryIcon,
            }"
          >
            <slot name="icon">
              <DefaultIconsNext aria-hidden="true" />
            </slot>
          </span>
        </template>
      </span>
    </template>
    <template v-else>
      <span v-if="$slots.icon" :class="{ 'cta-icon': !linkIconBigger }">
        <slot name="icon" />
      </span>
    </template>
    <!-- Label -->
    <component
      :is="headingLabel ? 'h1' : 'span'"
      v-if="$slots.label"
      ref="textSpan"
      class="text-effect"
      :class="{
        'transition-[margin] duration-300 ease-out':
          animatedIcon && anatomy !== 'link',
        '[&:not(:only-child)]:ml-3.5 [&:not(:only-child)]:group-hover:ml-0 [&:not(:only-child)]:group-focus:ml-0':
          iconPosition == 'right' && animatedIcon && anatomy !== 'link',
        '[&:not(:only-child)]:mr-3.5 [&:not(:only-child)]:group-focus:mr-0':
          iconPosition == 'left' && animatedIcon && anatomy !== 'link',
      }"
    >
      <slot name="label"></slot>
    </component>
  </component>
</template>
<style lang="scss" scoped>
.cta-icon {
  :deep(svg) {
    @apply h-5 w-5;
  }
}
</style>
