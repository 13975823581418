// If the user tries to access the follow routes when site is not shoppable, they will be redirected to the home page.
export default defineNuxtRouteMiddleware(async to => {
  const { isShoppable } = await useIsShoppable()
  const {
    cartUrl,
    checkoutUrl,
    myAccount,
    help,
    helpSection,
    helpContactUs,
    homePath,
    getCanonicalUrl,
  } = useRouteHelper()
  if (
    (to.path === cartUrl ||
      to.path === cartUrl + '/' ||
      to.path === checkoutUrl ||
      to.path === checkoutUrl + '/' ||
      to.path === myAccount ||
      to.path === myAccount + '/' ||
      to.path === help ||
      to.path === help + '/' ||
      to.path === helpContactUs ||
      to.path === helpContactUs + '/' ||
      to.path === helpSection('contact-us/contact-form') ||
      to.path === helpSection('contact-us/contact-form/')) &&
    !isShoppable.value
  ) {
    const path = getCanonicalUrl(homePath)
    return navigateTo(path)
  }
})
