<script setup lang="ts">
import type { OrganismsModalProps } from './OrganismsModal.props'

const props = defineProps<OrganismsModalProps>()

const emit = defineEmits(['on-close'])

const { currentBrandTheme: brandTheme } = await useTheme()

const { isDialogOpen, closeDialog } = useDialog(props.id)
// SCROLL-LOCK BODY
const body = ref<HTMLElement>()
onMounted(() => {
  body.value = document?.body

  if (props.preventLockBodyScroll && isDialogOpen.value)
    document.documentElement.setAttribute('data-force-scroll', 'true')
})

const isLocked = useScrollLock(body)
watch(isDialogOpen, (isDialogOpen, wasDialogOpen) => {
  if (isDialogOpen !== wasDialogOpen) {
    if (props.preventLockBodyScroll) {
      if (isDialogOpen)
        document.documentElement.setAttribute('data-force-scroll', 'true')
      else document.documentElement.removeAttribute('data-force-scroll')
      return
    }

    isLocked.value = isDialogOpen
  }
})

const closePopUpSlide = () => {
  emit('on-close')
  closeDialog(props.id)
}

onKeyStroke(
  'Escape',
  e => {
    e.preventDefault()
    closePopUpSlide()
  },
  { dedupe: false }
)

const transition = {
  enter: 'duration-300 ease-out',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'duration-200 ease-in',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
} as const

const positionInset = {
  top: `top-0 left-0 right-0`,
  center: 'top-1/2 left-0 right-0 -translate-y-1/2',
  bottom: 'bottom-0 left-0 right-0',
}
</script>

<template>
  <HeadlessTransitionRoot appear :show="isDialogOpen" as="template">
    <HeadlessDialog
      as="div"
      class="relative"
      :class="usesHighestZIndex ? 'z-[999]' : 'z-50'"
    >
      <div
        class="modal-scrollbar fixed inset-0 overflow-y-auto"
        :class="withOverlay && 'bg-neutral-black bg-opacity-50'"
        @click="e => closeOnTouchOutside && closePopUpSlide()"
      >
        <HeadlessTransitionChild
          as="template"
          :enter="transition.enter"
          :enter-from="transition.enterFrom"
          :enter-to="transition.enterTo"
          :leave="transition.leave"
          :leave-from="transition.leaveFrom"
          :leave-to="transition.leaveTo"
        >
          <HeadlessDialogPanel
            class="my-auto content-center"
            :class="[
              autoHeight && position
                ? `dialog-panel fixed ${positionInset[position]} grid-standard container`
                : 'h-max min-h-full w-full',
              !autoHeight && darkBackground && 'bg-primitives-black',
              forceGlobalTheme ? 'theme-global' : `theme-${brandTheme}`,
            ]"
          >
            <div
              :class="[
                autoHeight &&
                  position &&
                  'columns-standard overflow-y-scroll p-6',
                !darkBackground &&
                  'border-primitives-grey-100 bg-background-secondary',
              ]"
              @click.stop
            >
              <div
                v-if="!hideCloseButton"
                :class="
                  autoHeight && position ? 'mb-6 flex justify-end' : 'contents'
                "
              >
                <button
                  :class="[
                    !autoHeight &&
                      'lg-custom:right-12 absolute right-4 z-50 md:right-6 xl:right-16',
                    isEditorialLookGrid
                      ? 'top-4'
                      : isHotspot
                        ? 'top-6'
                        : 'top-8',
                    {
                      'lg-custom:!right-8 !right-4 !top-6 lg:!right-8 lg:hidden xl:!right-8 ':
                        isShopTheLook,
                    },
                  ]"
                  :aria-label="`${$ts('close')} ${description ?? ''}`"
                  @click="() => closePopUpSlide()"
                >
                  <DefaultIconsClose
                    aria-hidden="true"
                    class="h-8 w-8"
                    :class="
                      darkBackground
                        ? 'text-primitives-white'
                        : 'text-text-primary'
                    "
                  />
                </button>
              </div>
              <slot name="body" class="my-auto" />
            </div>
          </HeadlessDialogPanel>
        </HeadlessTransitionChild>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<style scoped lang="scss">
.dialog-panel {
  max-height: 80%;
  grid-template-rows: minmax(0, 1fr);
}
</style>
