import type { Website } from './../index.d.ts'

export const useWebsite = () => {
  const currentWebsite = computed(() => useAppConfig().website)
  const deferredCurrentWebsite = useState<Website>('deferred-current-website')

  const isGlobalWebsite = computed(() => currentWebsite.value === 'global')
  const isDeferredGlobalWebsite = computed(
    () => deferredCurrentWebsite.value === 'global'
  )

  const isGiorgioArmaniWebsite = computed(
    () => currentWebsite.value === 'giorgio-armani'
  )

  const isEmporioArmaniWebsite = computed(
    () => currentWebsite.value === 'emporio-armani'
  )

  const isArmaniExchangeWebsite = computed(
    () => currentWebsite.value === 'armani-exchange'
  )

  const isEa7Website = computed(() => currentWebsite.value === 'ea7')

  const isBookAppointmentWebsite = computed(
    () =>
      currentWebsite.value === 'giorgio-armani' ||
      currentWebsite.value === 'emporio-armani' ||
      currentWebsite.value === 'ea7' ||
      currentWebsite.value === 'armani-exchange' ||
      currentWebsite.value === 'armani-casa'
  )

  const isBrand = (w: string) => (brands as string[]).includes(w)
  const viewTypePage = () => {
    let viewType = ''

    switch (true) {
      case viewType === 'product':
        return (viewType = 'pdp_page')
      case viewType === 'categorylvl1':
        return (viewType = 'plp_page')
      case viewType === 'wishlist':
        return (viewType = 'wishlist_page')
      case viewType === 'cart':
        return (viewType = 'cart_page')
      default:
        return null
    }
  }

  return {
    currentWebsite,
    deferredCurrentWebsite,
    isGlobalWebsite,
    isDeferredGlobalWebsite,
    isGiorgioArmaniWebsite,
    isEmporioArmaniWebsite,
    isArmaniExchangeWebsite,
    isEa7Website,
    isBookAppointmentWebsite,
    isBrand,
    viewTypePage,
  }
}
