export const useIsShoppable = async () => {
  const configs = useConfigs()
  const { currentWebsite } = useWebsite()

  const { shopEnabled } = await useBrandsSettings()
  const isCountryShoppable = computed(() => configs.value.is_shoppable ?? false)
  const isBrandShoppable = computed(
    () => shopEnabled(currentWebsite.value) ?? false
  )

  const isShoppable = computed(
    () => (isBrandShoppable.value && isCountryShoppable.value) ?? false
  )

  return {
    isCountryShoppable,
    isBrandShoppable,
    isShoppable,
  }
}
